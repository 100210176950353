/* General styles for header */
.header {
    text-align: center;
    padding: 20px;
    margin: 20px;
    font-size: 18px;
    font-family: 'Century', sans-serif;
  }
  
  /* Video container styles */
  .video-container {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .video-frame {
    width: 80%;
    height: 80vh;
  }
  
  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  /* Styles for image cards */
  .card {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .card-image {
    width: 60%;
    height: 100%;
    display: block;
  }
  
  .card-title {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    width: 60%;
    text-align: center;
    padding: 10px 0;
  }
  
  /* Styles for image grid */
  .image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0;
    padding-bottom: 20px;
    margin-left: 120px;
    margin-right: 120px;
    align-items: center;
    justify-content: center;
  }
  
  /* Styles for image gallery modal */
  .image-gallery-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-gallery-container {
    position: relative;
    width: 100%;
    height: 120%;
  }
  
  .image-gallery-close {
    position: absolute;
    margin-top: 80px;
    right: 20px;
    background: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    z-index: 1001;
  }
  .header h1{
    font-size: 50px;
    color: black;
  }
  /* Responsive styling */
  @media (max-width: 768px) {
    /* Adjust header font size for smaller screens */
    .header h1{
        font-size: 29px;
    }
    .header p{
      font-size: 16px;
      margin-left: 10%;
    }
  
    /* Ensure the background video covers the screen properly */
    .video-frame {
      width: 100%;
      height: 50vh;
    }
  
    /* Adjust image grid layout for smaller screens */
    .image-grid {
      grid-template-columns: 1fr;
      margin-left: 20px;
      margin-right: 20px;
    }
  
    /* Adjust image card layout for smaller screens */
    .card-image {
      width: 100%;
      height: auto;
    }
    .card-title {
      width: 100%;
    }
    /* Adjust image gallery container for smaller screens */
  /* Image gallery modal styling */
.image-gallery-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-gallery-container {
    position: relative;
    width: 100%;
    height: 120%;
  }
  
  .image-gallery-close {
    position: absolute;
    margin-top: 20px;
    right: 10px;
    background: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    z-index: 1001;
  }
  
  /* Responsive styling */
  
    /* Adjust header font size for smaller screens */
    .header h1, .header_commercial h1, .header_apartments h1 {
      font-size: 32px;

    }
  
   
  
    /* Adjust image gallery modal for smaller screens */
    .image-gallery-container {
      width: 90%;
      height: 90%;
    }
  
    .image-gallery-close {
      font-size: 14px;
      padding: 5px 8px;
    }
  }
  