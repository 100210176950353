.about-us-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: justify;
    font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.5;
    letter-spacing: 0.05em;
  
}

.quote-section {
  text-align: justify;
  font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.5;
  letter-spacing: 0.05em;
    margin-bottom: 20px;
}

.quote {
  text-align: justify;
  font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.5;
  letter-spacing: 0.05em;
  font-style: italic;
}

.main-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.info {
    flex: 1;
    padding-right: 20px;
    text-align: justify;
    font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.5;
    letter-spacing: 0.05em;
}

.info h2 {
  text-align: justify;
  font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.5;
  letter-spacing: 0.05em;
    margin-bottom: 10px;
}

.image {
    flex: 1;
    text-align: center;
}

.image img {
    width: 50%;
    height: 50%;
    border-radius: 5080px;
}
.image1 img {
  width: 100%;
  height: 50%;
}
.stats-team-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
  }
  
  .stat-item {
    text-align: center;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
  }
  
  .stat-item h1 {
    font-size: 3em;
    margin: 0;
    color: #333;
  }
  
  .stat-item p {
    margin: 10px 0;
    color: #666;
  }
  .info1 p{
    font-size: large;
  }

 
  @media (max-width: 768px) {
    .main-content {
        flex-direction: column;
    }

    .info {
        padding-right: 0;
        text-align: center;
        margin-left: 10%;

    }

    .image img {
        width: 80%;
    }
    .main-content1 {
      flex-direction: column;
  }

  .info1 h2,p{
      text-align: center;
      margin-right: 10%;
  }

  .image1 img {
    padding-bottom: 0%;
      width: 100%;
      height: 100%;
  }
  .quote-section {
    text-align: center;
    margin-bottom: 20px;
    margin-left: 10%;
}

.quote {
    font-style: italic;
}
}