section {
    display: flex;
    align-items: center;
    justify-content: center;
    /* letter-spacing: 0.2em;  */
    /* font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: white;
    font-size: 8px;
    line-height: 1.5; */
    text-align: justify;
    font-size: 18px;
    font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.5;
    letter-spacing: 0.05em;
}

#background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1; /* Ensure the video is behind other content */
  }
.service_home, .creations {
    display: flex;
    flex-direction: row;
    font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: white;
    font-size: 8px;
    line-height: 1.5;
    /* letter-spacing: 0.2em;  */
    text-align: justify;
}

h2{
    font-size: 35px;
}

.service-info{
    flex: 1;
    padding: 20px;
    /* text-align: right; */
    font-size: 18px;
    font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
    /* font-weight: 500; */
    /* letter-spacing: 0.2em;  */
    text-align: justify;


}
.creations-info{
    flex:1;
    padding: 20px;
    text-align: justify;
    font-size: 18px;
    font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.5;
    letter-spacing: 0.05em;
}

.service-image, .creations-image {
    flex: 1;
    padding: 20px;
    cursor: pointer; /* Show pointer cursor on hover for clickable effect */
}

.service-image img, .creations-image img {
    width: 100%;
    height: auto;
    
}

.creations {
    flex-direction: row-reverse;
}

.info{
    background-color: white;
    margin-top: 30px;
}

/* Filename - Image Toggle */

.App {
	text-align: center;
	margin: auto;
	width: 50rem;
	/* justify-content: center; */
	background-color: white;
}
.container {
	display: flex;
	justify-content: center;
	margin-left: 0px;
  margin-right: 0px;
	padding-top: 50px;
	padding-bottom: 30px;
}
.arrow-right:hover {
  color: #007BFF; /* Change color on hover */
}
.service-info a {
  text-decoration: none; /* Removes the underline */
}
.creations-info a{
  text-decoration:none;
}
.flip-card {
  perspective: 2000px;
  width:500px; /* Double the original width (500px) */
  height: 450px; /* Double the original height (450px) */
	position: relative;
	transform-style: preserve-3d;
	transition: transform 0.5s;
	padding: 5px;
}

.flip-card.flipped {
	transform: rotateY(180deg);
}

.flip-card-inner1,
.flip-card-inner2,
.flip-card-inner3 {
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
	width: 100%;
	height: 100%;
	position: absolute;
	backface-visibility: hidden;
	background-size: cover; /* Ensures the image covers the entire element */
	background-position: center; /* Centers the image */
}

.flip-card-front {
	background-image: url('../Assets/before.jpg'); 
}

.flip-card-back {
	background-image: url('../Assets/after.jpg'); 
	transform: rotateY(180deg);
}

.card-content {
	padding: 20px;
	text-align: center;
}


.card-content {
	padding: 0px;
  width: 100%;
	font-size: 26px;
	margin-top: 410px;
	border: none;
	border-radius: 0px;
	cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
    text-align: center;
    font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
    letter-spacing: 0.1em; 
    color:white;
}
 
  /* Define keyframes for the animation */
@keyframes slideInLeft {
  from {
      transform: translateX(-100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes slideInRight {
  from {
      transform: translateX(100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}
  /* Apply animation to elements with the respective classes */
.animate-left {
  animation: slideInLeft 1s ease forwards;
}

.animate-right {
  animation: slideInRight 1s ease forwards;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blinking-border {
  margin: 20px 0;
  font-size: 24px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  padding: 10px;
  border: 3px solid black; /* Set the border color to black */
  display: inline-block; /* Center alignment for inline elements */
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background: white;
}

/* Remove the animation definition if it's no longer needed */
@keyframes blink {
  100% {
      border-color: transparent;
  }
  100% {
      border-color: black;
  }
}




@media only screen and (max-width: 768px) {

  .flip-card {
    perspective: 2000px;
    width: 500px; /* Double the original width (500px) */
    height: 450px; /* Double the original height (450px) */
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    padding: 5px;
    margin: 0 auto; /* Center the card */
    transform-origin: center center; /* Ensure the card flips from its center */
  }


 .card-content{
  margin-top: 420px;
 }

  section {
    flex-direction: column;
    font-size: 14px;
  }

  .service_home, .creations {
    flex-direction: column;
    font-family: 'Century', sans-serif;

  }

  .service-info, .creations-info {
    text-align: center;
    padding: 10px;
    font-size: 16px;
    font-family: 'Century', sans-serif;
    

  }

  .service-image, .creations-image {
    padding: 10px;
    margin-right: 10%;
  }

 
  h2 {
    font-size: 28px;
  }

  .info h2 {
    text-align: center;
    margin-right: 10%;
  }

  .container {
    flex-direction: column;
    margin-left: 0px;
    margin-right: 100px;
    justify-content: center;
  }

  .App {
    text-align: center;
    margin-left: 19%;
    width: 0rem;
    max-width: 50px;
    background-color: white;
  }    
  .flip-card {
        width: 100%; /* Adjust width for smaller screens */
        height: 100%; /* Adjust height for smaller screens */
        margin: 10px auto;
        width: 250px; /* Double the original width (500px) */
    height: 450px; /* Center and add spacing */
    }

    .flip-card-inner1,
    .flip-card-inner2,
    .flip-card-inner3 {
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
    position: absolute; /* Ensure the inner cards do not affect the outer card's position */
    top: 0;
    left: 0;
    }

    .flip-button {
        font-size: 20px;
    }


    .container {
        flex-direction: column;
        margin-left: 50px;
        margin-right: 100px;
        justify-content: center;
    }

    .App {
        text-align: center;
        margin-left: 19%;
        width: 0rem;
        max-width: 50px;
        background-color: white;
    }
h2{
  text-align:center;
  margin-right: 10%;
}
.blinking-border {
  margin: 20px 0;
  font-size: 16px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  padding: 10px;
  border: 3px solid transparent;
  animation: blink 1s infinite;
  display: inline-block; /* Center alignment for inline elements */
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background: white;
}


}
