.contact-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-family: 'Century', sans-serif;

  }
  
  .contact-details, .contact-form {
    width: 45%;
  }
  
  .contact-details h2, .contact-form h2 {
    margin-bottom: 10px;
  }
  
  .contact-details p {
    margin: 5px 0;
  }
  
  .send-sms-btn {
    padding: 10px;
    background-color: #FFC107;
    border: none;
    cursor: pointer;
  }
  
  .contact-form ul {
    list-style-type: none;
    padding: 0;
  }
  
  .contact-form li {
    margin: 5px 0;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  .form-input {
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-now-btn {
    padding: 10px;
    background-color: #D32F2F;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .contact-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .contact-details {
    width: 45%;
  }
  
  .contact-form {
    width: 45%;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .contact-now-btn {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .contact-now-btn:hover {
    background-color: #45a049;
  }
  
  .error {
    color: red;
    margin-bottom: 15px;
  }
  

  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .contact-container {
      flex-direction: column; /* Stack items vertically on small screens */
    }
  
    .contact-details, .contact-form {
      width: 100%; /* Full width on small screens */
      margin-bottom: 20px; /* Add some space between sections */
    }
  
    .form-input {
      padding: 10px;
      width: 100%;
      box-sizing: border-box;
    }
    .contact-container {
      display: flex;
      flex-direction: column; /* Stack items vertically on small screens */
      padding: 20px;
      font-family: Arial, sans-serif;
    }
    
    .contact-details, .contact-form {
      width: 100%; /* Full width on small screens */
      margin-bottom: 20px; /* Add some space between sections */
    }
    
    .contact-details h2, .contact-form h2 {
      margin-bottom: 10px;
    }
    
    .contact-details p {
      margin: 5px 0;
    }
    
    .contact-form ul {
      list-style-type: none;
      padding: 0;
    }
    
  
  
  .contact-form li {
    margin: 5px 0;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  .form-input {
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-now-btn {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .contact-now-btn:hover {
    background-color: #45a049;
  }
  
  .error {
    color: red;
    margin-bottom: 15px;
  }
  }
  