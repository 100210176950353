.serviceContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: justify;
  font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.5;
  letter-spacing: 0.05em;
}

.serviceTitle {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
  font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.5;
  letter-spacing: 0.05em;
}

.serviceContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  text-align: justify;
  font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.5;
  letter-spacing: 0.05em;
}

.serviceImage {
  flex: 1;
  max-width: 300px;
  width: 100%;
  margin: 0 20px;
  animation: slideIn 1s ease-out;
  height: 200px; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.serviceImage img {
  max-width: 100%;
  max-height: 100%; 
  height: auto;
}

.serviceInfo {
  flex: 2;
  min-width: 300px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  animation: slideIn 1s ease-out;
}

.serviceInfo p {
  margin: 10px 0;
}


@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}



img {
  width: 100%;
  height: auto;
}
.info1 p,h2 {
  margin-left: 50px;
}
.info h2 {
  margin-left: 5px;
}




/* Keyframe animation for sliding in elements */
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Styling for all h2 elements */
@media (max-width: 768px) {


/* Ensure all images are responsive */
img {
  width: 100%;
  height: auto;
}

/* Specific styling for paragraphs and h2 inside .info1 class */
.info1 p, h2 {
  margin-left: 50px;
}

/* Specific styling for h2 inside .info class */
.info h2 {
  margin-left: 5px;
}

/* Media query for mobile devices */

  .serviceContent {
    flex-direction: column;
    margin-right: 10%; /* Stack items vertically */
  }

  .serviceImage, .serviceInfo {
    width: 100%; /* Full width on mobile */
    margin: 10px 0; /* Add space between elements */
  }

  .serviceImage {
    max-width: none; /* Remove max-width constraint */
    height: auto; /* Auto height for better responsiveness */
  }

  .serviceInfo {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .serviceTitle {
    font-size: 2em; /* Slightly smaller font size for the title */
  }
}
