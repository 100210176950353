.navbar-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust as necessary for your design */
    overflow: hidden;
    
  }
  .navbar {
    position: relative;
    z-index: 1;
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    font-family: 'Century', sans-serif;

  }
  
  .nav {
    flex: 1;
    text-align: center;
    margin-top: 0px;

  }
  
  .nav ul li {
    list-style: none;
    display: inline-block;
    padding: 8px 12px;
    position: relative;
  }
  
  .nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 25px;
  }
  
  .nav ul li::after {
    content: "";
    width: 0%;
    height: 2px;
    background: rgb(241, 238, 238);
    display: block;
    margin: auto;
    transition: 0.5s;
  }
  
  .nav ul li:hover::after {
    width: 100%;
    height: 2px;
    display: block;
    margin: auto;
    transition: 0.5s;
  }
  
  .logo {
    margin-top: 2%;
    align-items: center;
    margin-left: 40%;
    width:20%;
    align-items: center;
  }
  .nav .dropdown {
    position: relative;
  }
  
  .nav .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    /* box-shadow: 0px 5px 10px rgba(0,0,0,0.2); */
    border-radius: 1px;
    z-index: 1;
    font-size: 2%; /* Adjust font size */
    text-align: left;
    letter-spacing: 0.05em;
  }
  
  .nav .dropdown-content a {
    color: black;
    padding: 1px 1px;
    text-decoration: none;
    display: block;
  }
  
  .nav .dropdown-content a:hover {
    background-color: black;
    color: white;
    border-radius: 1px;

  }
  
  .nav .dropdown:hover .dropdown-content {
    display: block;
  }

  .navbar-container.no-video {
    height: 30vh;
    background-color: darkslategray; /* Reduced height for other pages */
    
  }
  .navbar-container.no-video .nav {
    margin-top: 0; /* Adjust margin for nav links when video is not present */
  }
  
  .nav .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .navbar-container.no-video {
    height: 30vh;
    background-color: black; /* Reduced height for other pages */
  }
  
  .navbar-container.no-video .nav {
    margin-top: 0; /* Adjust margin for nav links when video is not present */
  }
  
  /* Hamburger Menu Styles */
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    z-index: 2;
  }
  
  .hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    transition: 0.4s;
  }
  
  
  
  /* Responsive Styles */
  @media (max-width: 768px) {
     .navbar-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust as necessary for your design */
    overflow: hidden;
  }
  
  .navbar-container.no-video {
    height: 20vh; /* Reduced height for other pages */
    background-color: black; /* Adjust background color as needed */
    transition: height 0.3s ease;
  }
  
  .navbar-container.no-video.expanded {
    height: 70vh; /* Increased height when hamburger is clicked */
  }
  
  .navbar {
    position: relative;
    z-index: 1;
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center; /* Align items in the center */
  }
  
  .nav {
    flex: 1;
    text-align: center;
    margin-top: 0px;
  }
  
  .nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .nav ul li {
    display: inline-block;
    padding: 8px 12px;
    position: relative;
  }
  
  .nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 25px;
  }
  
  .nav ul li::after {
    content: "";
    width: 0%;
    height: 2px;
    background: rgb(241, 238, 238);
    display: block;
    margin: auto;
    transition: 0.5s;
  }
  
  .nav ul li:hover::after {
    width: 100%;
    height: 2px;
    display: block;
    margin: auto;
    transition: 0.5s;
  }
  
  .logo {
    margin-top: 9%;
    width: 120%; /* Adjust width as necessary */
  }
  
  .nav .dropdown {
    position: relative;
  }
  
  .nav .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
    border-radius: 2px;
    z-index: 1;
  }
  
  .nav .dropdown-content a {
    color: black;
    padding: 2px 2px;
    text-decoration: none;
    display: block;
  }
  
  .nav .dropdown-content a:hover {
    background-color: black;
    color: white;
    border-radius: 2px;
  }
  
  .nav .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Hamburger Menu Styles */
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    z-index: 2;
  }
  
  .hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: rgb(77, 76, 76);
    margin: 2px 0;
    transition: 0.4s;
  }
  
  /* Mobile Menu Styles */
  .nav.open {
    display: block;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    text-align: center;
  }
  
  .nav ul li {
    display: block;
    padding: 10px;
  }
  
  
  
    .nav {
      display: none;
    }
  
    .hamburger {
      display: flex;
      margin-top: 0%;
      margin-left: 90%; /* Position the hamburger to the far right */
    }
  
    .logo {
      width: 40%; /* Adjust width as necessary */
      margin-left: 30%; /* Center the logo */
      align-items: center;
    }
  
    .navbar {
      justify-content: space-between;
      align-items: center; /* Align items in the center */
    }
  
    .navbar-container.no-video.expanded {
      height: 50vh; /* Adjust height when hamburger is clicked */
    }
  
    .nav.open {
      display: block;
      position: absolute;
      top: 35px;
      left: 0;
      width: 100%;
      text-align: center;
    }
  
    .nav ul li {
      display: block;
      padding: 5px;
    }
  
    .nav ul li:hover::after {
      width: 25%;
      height: 2px;
      display: block;
      margin: auto;
      transition: 0.5s;
    }
  }
  